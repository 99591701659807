<template>
  <div class="ExamAbnormalScore">
    <detailsHeader
      ref="detailsHeader"
      router-url="unionExamRanking"
      :query="$route.query"
    ></detailsHeader>
    <div class="tab-box">
      <div class="search-box">
        学校：
        <el-select
          v-model="search.schoolId"
          placeholder="请选择"
          filterable
          @change="handleClick()"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option
            v-for="(item, i) in schoolList"
            :key="i"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
        <div class="btn-item">
          <div
            v-if="showAllScore"
            :class="{ on: search.subjectId == 0 }"
            @click="checkSub(0)"
          >
            总分
          </div>
          <div
            v-for="(item, index) in paperList"
            :key="index"
            :class="{ on: search.subjectId == item.subjectId }"
            @click="checkSub(item.subjectId)"
          >
            {{ item.subjectName }}
          </div>
        </div>
      </div>
      <div v-if="!search.subjectId" class="search-box search-box-item">
        考生任一科成绩在
        <el-input
          v-model="search.high"
          style="width: 80px"
          placeholder=""
          oninput="value=value.replace(/[^0-9.]/g,'')"
          @blur="search.high = $event.target.value"
        ></el-input>
        %以上，但<el-select
          v-model="search.number"
          style="width: 80px"
          placeholder=""
        >
          <el-option
            v-for="(item, index) in paperList"
            :key="index"
            :label="'&lt;=' + (index + 1)"
            :value="index + 1"
            >&lt;={{ index + 1 }}
          </el-option>
          <!-- <el-option label="&lt;=2" :value="2">&lt;=2</el-option>
          <el-option label="&lt;=3" :value="3">&lt;=3</el-option> -->
        </el-select>
        科成绩在
        <el-input
          v-model="search.low"
          style="width: 80px"
          placeholder=""
          oninput="value=value.replace(/[^0-9.]/g,'')"
          @blur="search.low = $event.target.value"
        >
        </el-input>
        %分以下
        <el-button type="primary" @click="getDataList()">检查</el-button>
      </div>
      <div v-else class="search-box search-box-item">
        快捷设置
        <el-select
          v-model="rapid"
          placeholder="请选择"
          style="width: 200px"
          @change="checkSet()"
        >
          <el-option label="主观题有分，客观题0分" :value="1"> </el-option>
          <el-option label="主观题0分，客观题有分" :value="2"> </el-option>
        </el-select>
        客观题
        <el-select
          v-model="search.objType"
          style="width: 110px"
          placeholder="请选择"
        >
          <el-option label="小于等于" :value="0"> </el-option>
          <el-option label="大于" :value="1"></el-option>
        </el-select>
        <el-input
          v-model="search.objScore"
          style="width: 80px"
          placeholder=""
          oninput="value=value.replace(/[^0-9.]/g,'')"
          @blur="search.objScore = $event.target.value"
        >
        </el-input
        >分， 主观题
        <el-select
          v-model="search.subType"
          style="width: 110px"
          placeholder="请选择"
        >
          <el-option label="小于等于" :value="0"> </el-option>
          <el-option label="大于" :value="1"></el-option>
        </el-select>
        <el-input
          v-model="search.subScore"
          style="width: 80px"
          placeholder=""
          oninput="value=value.replace(/[^0-9.]/g,'')"
          @blur="search.subScore = $event.target.value"
        >
        </el-input
        >分
        <el-button type="primary" @click="getDataList()">检查</el-button>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="dataListLoading"
        border
        :data="dataList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column align="center" prop="studentName" label="姓名">
        </el-table-column>
        <el-table-column
          align="center"
          prop="studentExamId"
          label="考号"
          width="200px"
        >
        </el-table-column>
        <el-table-column align="center" prop="schoolName" label="学校">
        </el-table-column>
        <template v-if="examInformation.id && examInformation.examModel">
          <el-table-column align="center" prop="classNum" label="行政班">
            <template slot-scope="{ row }">
              {{ row.classNum }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="combination" label="选考组合">
          </el-table-column>
        </template>
        <template v-if="examInformation.id && !examInformation.examModel">
          <el-table-column align="center" prop="classNum" label="班级">
          </el-table-column>
        </template>

        <template v-if="getquestionShow()">
          <el-table-column
            v-for="(v, i) in titleList"
            :key="i"
            header-align="center"
            align="center"
            :label="v.subjectName"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.subjectScoreList[i].score == -1">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="非选考科目"
                  placement="top"
                >
                  <i class="el-icon-minus"></i>
                </el-tooltip>
              </template>
              <template v-if="scope.row.subjectScoreList[i].score == -2">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="暂无查看权限"
                  placement="top"
                >
                  <span>&nbsp;</span>
                  <!-- ? -->
                  <!-- <i class="el-icon-question"></i> -->
                </el-tooltip>
              </template>
              <template
                v-if="
                  scope.row.subjectScoreList[i].score != -1 &&
                  scope.row.subjectScoreList[i].score != -2
                "
              >
                <span
                  class="total-score"
                  :class="{
                    'total-score-ff': roleId == 5,
                    'total-score-err': scope.row.subjectScoreList[i].type == 1,
                  }"
                  @click="showCard(scope.row, v)"
                >
                  {{ scope.row.subjectScoreList[i].score }}
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :key="scoreKey + 'scoreKey'"
            prop="totalScore"
            header-align="center"
            align="center"
            width="110px"
            label="全科总分"
          >
            <template slot-scope="{ row }">
              <!-- {{ row.totalScore }} -->
              <template v-if="row.totalScore == -2">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="暂无查看权限"
                  placement="top"
                >
                  <span>&nbsp;</span>
                  <!-- <span>?</span> -->
                  <!-- <i class="el-icon-question"></i> -->
                </el-tooltip>
              </template>
              <template v-else>
                {{ row.totalScore }}
              </template>
            </template>
          </el-table-column>
        </template>

        <template v-else>
          <el-table-column align="center" prop="objScore" label="客观题">
            <template slot-scope="scope">
              {{ scope.row.objScore === null ? "-" : scope.row.objScore }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="subScore" label="主观题">
            <template slot-scope="scope">
              {{ scope.row.subScore === null ? "-" : scope.row.subScore }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="单科总分" prop="subjectScore">
            <template slot-scope="scope">
              <template v-if="scope.row.subjectScore == -1">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="非选考科目"
                  placement="top"
                >
                  <i class="el-icon-minus"></i>
                </el-tooltip>
              </template>
              <template v-if="scope.row.subjectScore == -2">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="暂无查看权限"
                  placement="top"
                >
                  <i class="el-icon-minus"></i>
                </el-tooltip>
              </template>
              <template
                v-if="
                  scope.row.subjectScore != -2 && scope.row.subjectScore != -1
                "
              >
                <span
                  class="total-score"
                  :class="{ 'total-score-ff': roleId == 5 }"
                  @click="showCard(scope.row)"
                  >{{ scope.row.subjectScore }}</span
                >
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import detailsHeader from "@/components/detailsHeader.vue";
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";
import { checkProvisionalList, getSubjectlist } from "@/core/api/exam/examRank";
export default {
  name: "ExamAbnormalScore",
  components: { detailsHeader },
  data() {
    const { roleId } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    return {
      roleId: roleId,
      dataListLoading: false,
      showAllScore: true,
      examInformation: {},
      paperList: [],
      search: {
        schoolId: "",
        objType: 0,
        subType: 1,
        objScore: 0,
        subScore: 0,
        subjectId: 0,
        high: 1,
        number: 1,
        low: 1,
      },
      scoreKey: 1,
      rapid: 1,
      schoolList: [],
      titleList: [],
      dataList: [],
    };
  },
  async created() {
    this.getSchoolList();
    await this.getExamInfo();
    await this.getSub();
    this.getDataList();
  },
  methods: {
    async getSub() {
      let data = {
        examId: this.$route.query.examId,
      };
      await getSubjectlist(data)
        .then((res) => {
          this.paperList = res.data.data.map((item) => {
            item.paperId = item.examPaperId;
            return item;
          });
          this.subjectList = JSON.parse(JSON.stringify(this.paperList));

          if (this.examInformation.paperList.length != this.paperList.length) {
            this.showAllScore = false;
            if (this.paperList.length > 0) {
              this.search.subjectId = this.paperList[0].subjectId;
            }
          }
          if (this.$route.query.subjectId) {
            let ids = this.paperList.map((item) => item.subjectId);

            if (ids.indexOf(Number(this.$route.query.subjectId)) != -1) {
              this.search.subjectId = this.$route.query.subjectId;
            }
          }
        })
        .catch(() => {});
    },
    checkSet() {
      if (this.rapid == 1) {
        this.search.objType = 0;
        this.search.subType = 1;
        this.search.objScore = 0;
        this.search.subScore = 0;
      }
      if (this.rapid == 2) {
        this.search.objType = 1;
        this.search.subType = 0;
        this.search.objScore = 0;
        this.search.subScore = 0;
      }
      this.dataList = [];
      this.getDataList();
    },
    handleClick() {
      this.getDataList();
    },
    // 答题卡页面
    showCard(val, item) {
      let paperId = "";

      if (item) {
        paperId = item.paperId;
      } else {
        let paperList = this.paperList.filter(
          (index) => index.subjectId == this.search.subjectId
        );
        if (paperList.length > 0) {
          paperId = paperList[0].examPaperId;
        } else {
          paperId = this.paperList[0].examPaperId;
        }
      }

      let data = {
        pageIndex: this.pageIndex,
        studentExamId: val.studentExamId,
        studentId: val.studentId,
        examId: this.$route.query.examId,
        examPaperId: paperId,
        urlName: "ExamAbnormalScore",
        subjectId: this.$route.query.subjectId,
      };

      this.$router.push({
        name: "unionExamStudentScore",
        query: data,
      });
    },
    setTitleDataList() {
      this.titleList = [];
      if (this.dataList.length > 0) {
        this.titleList = this.dataList[0].subjectScoreList;
      }
      this.titleList.map((item) => {
        this.paperList.map((index) => {
          if (item.subjectId == index.subjectId) {
            item.paperId = index.paperId;
          }
        });
      });
    },
    getquestionShow() {
      let type = false;
      if (this.search.subjectId == 0) {
        type = true;
      }
      return type;
    },
    getDataList() {
      this.dataListLoading = true;
      this.dataList = [];
      let data = {
        examId: this.$route.query.examId,
      };
      Object.assign(data, this.search);

      data.objScore = data.objScore || 0;
      data.subScore = data.subScore || 0;
      data.high = data.high || 0;
      data.low = data.low || 0;

      checkProvisionalList(data)
        .then((res) => {
          this.dataList = res.data.data;
          this.dataListLoading = false;
          this.setTitleDataList();
        })
        .catch(() => {
          this.dataListLoading = false;
        });
    },
    checkSub(val) {
      this.search.subjectId = val;
      let data = JSON.parse(JSON.stringify(this.$route.query));
      data.subjectId = val;
      data.time = new Date().getTime();
      this.$router.push({
        query: data,
      });
      this.getDataList();
    },
    async getSchoolList() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });
      this.schoolList = res.data.data;
    },
    //获取考试基本信息
    async getExamInfo() {
      await getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;

        // this.paperList = res.data.data.paperList.map((item) => {
        //   return {
        //     paperId: item.paperId,
        //     subjectName: item.subjectName,
        //     subjectId: item.subjectId,
        //   };
        // });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.ExamAbnormalScore {
  padding-top: 18px;
  .total-score {
    color: $primary-color;
    cursor: pointer;
  }
  .total-score-ff {
    cursor: not-allowed;
    color: #777473;
  }
  .total-score-err {
    cursor: pointer;
    color: red;
  }
  .tab-box {
    padding: 18px;
    background-color: #ffffff;
    margin-top: 18px;
    margin-bottom: 40px;
    .search-box-item {
      .el-button {
        margin-left: 12px;
      }
      .el-select {
        margin: 0 8px;
      }
      .el-input {
        margin: 0 8px;
      }
    }
    .search-box {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      .el-select {
        width: 160px;
        margin-right: 8px;
      }
      .btn-item {
        display: flex;
        align-items: center;

        > div {
          padding: 6px 18px;
          border-radius: 4px;
          border: 1px solid #d5d6db;
          margin-right: 8px;
          padding: 6px 18px;
          line-height: 18px;
          cursor: pointer;
        }
        .on {
          color: #2474ed;
          border-color: #2474ed;
        }
      }
    }
  }
}
</style>
